import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Toaster } from 'react-hot-toast';
import PoweredBy from './components/PoweredBy/PoweredBy';
import UserInfo from './components/UserInfo/UserInfo';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import CkEditor from './views/CkEditor/CkEditor';
import JoditEditor from './views/JoditEditor/JoditEditor';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([{
  path: "/",
  element: <JoditEditor />
},
{
  path: "ck-editor",
  element: <CkEditor />
},
{
  path: "jodit-editor",
  element: <JoditEditor />
}
])

root.render(<>
  <Toaster />
  <UserInfo />
  <RouterProvider router={router} />
  {/* <App /> */}
  <PoweredBy />
</>
);
